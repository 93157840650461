<template>
  <div class="home">
<Navigation/>
    <div class="section bg-primary-3">
    <div class="container text-center">
      <h1>Under Contract Templates</h1>
      <div class="text-large">Share the love and celebrate.</div>
    </div>
  </div>
<Templates Category="under-contract-templates" />


  </div>

</template>

<script>
import Navigation from "@/components/Navigation.vue"
import Templates from "@/components/TemplateCollection.vue"

export default {
  name: "home",
  components: {
    Navigation,
    Templates
  }
};
</script>
